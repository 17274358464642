<template>
  <div class="tagMapBlog">
    <article-list :articleList="articleList"></article-list>
    <div class="page mt20">
      <Page
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @changeSize="changeSize"
        @changePage="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import ArticleList from "./base/ArticleList.vue";
import article from "@/api/article";
import Page from "./base/Page.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    ArticleList,
    Page,
  },
  data() {
    return {
      articleList: [],
      pageSize: 6,
      currentPage: 1,
      total: 0,
    };
  },
  watch: {
    $route(newVal) {
      const tags = newVal.params.tag;
      this.initData(tags);
    },
  },
  created() {
    const tag = this.$route.params.tag;
    this.initData(tag);
  },
  methods: {
    ...mapMutations(["handleScrollTop"]),
    initData(tags) {
      this.getArticle(tags);
      this.handleScrollTop(); //滚动条到顶部
    },
    async getArticle(tags) {
      const params = {
        limit: this.pageSize,
        page: this.currentPage,
        tags,
      };
      const res = await article.getArticleByTags(params);
      this.total = res.count;
      this.articleList = res.data;
    },
    changeSize(val) {
      this.pageSize = val;
      this.getArticle();
    },
    changePage(val) {
      this.currentPage = val;
      this.getArticle();
    },
  },
};
</script>

<style>
</style>